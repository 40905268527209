// import { RadioBillingType } from 'modules/billing/components'
import { BillingService } from 'modules/billing/services'
import React, { useEffect, useState } from 'react'
import { BillingLayoutState } from './types'
import Container from 'reactstrap/lib/Container'
import { ABanner } from 'shared/components/common'

import { BillingLetterPortPfg } from 'modules/billing/components/BillingLetterPortPDF/BillingLetterPortPfg'

const initialState: BillingLayoutState = {
  isLoading: false,
  hasBillingData: false,
  err: '',
  code: '',
  listCfdi: null,
  billingType: 'letterPorte'
}

export const BillingLayout: React.FunctionComponent<{ code: string }> = ({ code }) => {
  const [state, setState] = useState(initialState)

  const handleSearch = async () => {
    setState({ ...state, isLoading: true, hasBillingData: false })
    switch (state.billingType) {
      case 'letterPorte':
        try {
          const data = await BillingService.getComplementLEtterPortData(code)
          setState({ ...state, isLoading: false, hasBillingData: true, listCfdi: data })
        } catch (error) {
          setState({ ...state, isLoading: true, hasBillingData: false })
          console.log('Error al obtener los datos de la carta porte: ', error)
        }
        break
      default:
        console.log('')
        break
    }
  }

  useEffect(() => {
    if (code) {
      handleSearch()
    }
  }, [])

  return (
    <>
      <ABanner title="Tu factura" titleClassName="big-white" titleSize={12} />
      <Container className="mt-5">
        {!state.isLoading && !state.hasBillingData}

        {state.isLoading && <p>Cargando...</p>}

        {state.hasBillingData && <BillingLetterPortPfg listCfdi={state.listCfdi || []} />}
      </Container>
    </>
  )
}
