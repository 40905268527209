import React, { useRef } from 'react'
import { BillingLetterPortPdfProps } from './types'
import { CfdiLetterPorte, Timbre } from '../../services/BillingService/types'

import { Row, Col } from 'reactstrap'

import imageReport from 'img/allabordo_reports.png'
import 'styles/billing_pdf.scss'
import { useReactToPrint } from 'react-to-print'
import { BillingService } from 'modules/billing/services'
import QRCode from 'react-qr-code'
import { AButton } from 'shared/components/common'
import moment from 'moment'
import { saveAs } from 'file-saver'

// GENERAL COMPONENTES
const Divider = () => <hr className="hrl" />
const WrapperPdf: React.FunctionComponent<React.HTMLProps<HTMLDivElement>> = props => <div className="billing-pdf-body" {...props} />
const RowHeader: React.FunctionComponent<React.HTMLProps<HTMLSpanElement>> = props => <span className="billing-rows-header" {...props} />
const BoldText: React.FunctionComponent<React.HTMLProps<HTMLSpanElement>> = props => <span className="billing-bold-text" {...props} />
const TextSmall: React.FunctionComponent<React.HTMLProps<HTMLSpanElement>> = props => <span className="font-small font-black" {...props} />
const TitleBlack: React.FunctionComponent<React.HTMLProps<HTMLHeadingElement>> = props => <h5 className="title-black" {...props} />

const TableTh: React.FunctionComponent = ({ children }) => (
  <th className="secondary-text table-borde table-th">
    <div className="table-th-tr-content font-small">{children}</div>
  </th>
)
const TableTd: React.FunctionComponent = ({ children }) => (
  <td className="tableBorde">
    <div className="table-th-tr-content font-small">{children}</div>
  </td>
)
const HeaderParagraph: React.FunctionComponent<React.HTMLProps<HTMLParagraphElement>> = props => (
  <p className="billing-header-paragraph" {...props} />
)

// PRINCIPAL COMPONENT
export const BillingLetterPortPfg = (props: BillingLetterPortPdfProps) => {
  const printComponentRef = useRef(null)
  const { listCfdi } = props
  const uuID = listCfdi[0].timbre.TimbreFiscalDigital.UUID
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    documentTitle: uuID
  })

  const getQrSrc = (timbre: Timbre) => {
    return BillingService.generateQrSat({
      timbreFiscalId: timbre.TimbreFiscalDigital.UUID,
      emisorRfc: timbre.EmisorRfc,
      receptorRfc: timbre.ReceptorRfc,
      total: timbre.Total,
      sello: timbre.Sello
    })
  }

  const getCadenaCFDI = function(timbre: Timbre) {
    const FechaTimbrado = timbre.TimbreFiscalDigital.FechaTimbrado
    const UUID = timbre.TimbreFiscalDigital.UUID
    const RfcProvCertif = timbre.TimbreFiscalDigital.RfcProvCertif
    const SelloCFD = timbre.TimbreFiscalDigital.SelloCFD
    const SelloSAT = timbre.TimbreFiscalDigital.SelloSAT
    const NoCertificadoSAT = timbre.TimbreFiscalDigital.NoCertificadoSAT
    const cadenaOrginal = `||${UUID}|${FechaTimbrado}|${RfcProvCertif}${SelloCFD}|${SelloSAT}|${NoCertificadoSAT}||`
    return cadenaOrginal
  }

  const formatDate = (date: Date) => {
    return moment(date).format('DD/MM/yyyy HH:mm:ss')
  }

  const handleDownloadXml = () => {
    // @ts-ignore
    for (let i = 0; i < listCfdi.length; i += 1) {
      const tituloDoc = listCfdi[i].timbre.TimbreFiscalDigital.UUID
      const rawXml = listCfdi[i].xml
      const blobXml = new Blob([atob(rawXml)], { type: 'text/xml' })
      saveAs(blobXml, `${tituloDoc}.xml`)
    }
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <div>
          <AButton variant="med" onClick={handlePrint}>
            Imprimir pdf
          </AButton>
          &nbsp; &nbsp;
          <AButton variant="med" onClick={handleDownloadXml}>
            Descargar xml
          </AButton>
        </div>
      </div>
      <div ref={printComponentRef}>
        {listCfdi.map((cfdi: CfdiLetterPorte) => (
          <WrapperPdf key={cfdi.id}>
            <div>
              <div className="d-flex mb-5 mt-2">
                <div className="pr-5">
                  <img className="billing-header-image" src={imageReport} />
                </div>
                <div>
                  <h5 className="billing-header-title">{cfdi.timbre.EmisorNombre}</h5>
                  <HeaderParagraph>RFC:{cfdi.timbre.EmisorRfc}</HeaderParagraph>
                  <HeaderParagraph>Tipo de comprobante: {cfdi.tipo_cfdi}</HeaderParagraph>
                  <HeaderParagraph>Versión {cfdi.timbre.Version}</HeaderParagraph>
                  <HeaderParagraph>Lugar de Expedición: {cfdi.timbre.LugarExpedicion}</HeaderParagraph>
                  <HeaderParagraph>Fecha de Expedición: {formatDate(cfdi.timbre.Fecha)}</HeaderParagraph>
                  <HeaderParagraph>Régimen Fiscal: {cfdi.timbre.EmisorRegimenFiscal}</HeaderParagraph>
                  <HeaderParagraph>Folio Fiscal: {cfdi.timbre.TimbreFiscalDigital.UUID}</HeaderParagraph>
                  <HeaderParagraph>No de Serie de Certificado del CSD: {cfdi.timbre.NoCertificado}</HeaderParagraph>
                </div>
              </div>

              <Divider />

              <RowHeader>Datos del cliente</RowHeader>
              <div className="d-flex w-100">
                <div className="d-flex w-50">
                  <div className="mr-5">
                    <BoldText>Cliente:</BoldText>
                  </div>
                  <div>{cfdi.timbre.ReceptorNombre}</div>
                </div>
                <div className="d-flex w-50">
                  <div className="mr-5">
                    <BoldText>Uso de CFDI:</BoldText>
                  </div>
                  <div>{cfdi.timbre.ReceptorRegimenFiscal}</div>
                </div>
              </div>
              <div>
                <div className="d-flex w-50">
                  <div className="mr-5">
                    <BoldText>Rfc:</BoldText>
                  </div>
                  <div className="ml-4">{cfdi.timbre.ReceptorRfc}</div>
                </div>
              </div>

              <Divider />

              <table className="w-100">
                <thead>
                  <tr>
                    <TableTh>Cantidad</TableTh>
                    <TableTh>ProdServicio</TableTh>
                    <TableTh>CveUnidad</TableTh>
                    <TableTh>Descripción</TableTh>
                    <TableTh>Valor Unitario</TableTh>
                    <TableTh>Descuento</TableTh>
                    <TableTh>Importe</TableTh>
                  </tr>
                </thead>
                <tbody className="trTable">
                  {cfdi.timbre.Concepto.map(concept => (
                    <tr ng-if="cfdi.timbre.Concepto.length> 0" key={concept.ClaveProdServ}>
                      <TableTd>{concept.Cantidad}</TableTd>
                      <TableTd>{concept.ClaveProdServ}</TableTd>
                      <TableTd>{concept.ClaveUnidad}</TableTd>
                      <TableTd>{concept.Descripcion}</TableTd>
                      <TableTd>{concept.ValorUnitario}</TableTd>
                      <TableTd>{concept.Descuento === undefined ? 0 : concept.Descuento}</TableTd>
                      <TableTd>{cfdi.timbre.Concepto[0].Importe}</TableTd>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Divider />

              <Row>
                <Col sm={6}>
                  <RowHeader style={{ margin: 0 }}>Total Con letra:</RowHeader>
                </Col>
                <Col>
                  <div className="w-100 d-flex text-right justify-content-end">
                    <RowHeader style={{ margin: '0px 10px' }}>Subtotal</RowHeader>
                    <TextSmall>{cfdi.timbre.SubTotal}</TextSmall>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <TextSmall>({cfdi.timbre.totalLetra} 00/100 MX)</TextSmall>
                </Col>
                <Col>
                  <div className="w-100 d-flex text-right justify-content-end">
                    <RowHeader style={{ margin: '0px 10px' }}>IVA Trasladado (% {cfdi.timbre.TasaOCuota.substring(0, 4)})</RowHeader>
                    <TextSmall>{cfdi.timbre.ImporteImpuesto}</TextSmall>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="w-100 d-flex text-right justify-content-end">
                    <RowHeader style={{ margin: '0px 10px' }}>Total</RowHeader>
                    <TextSmall>{cfdi.timbre.Total}</TextSmall>
                  </div>
                </Col>
              </Row>
            </div>

            <div style={{ marginTop: 100 }}>
              <b>SELLO DIGITAL DEL CFDI</b>
              <p className="AjusteSello" style={{ fontSize: 10, color: '#000' }}>
                {cfdi.timbre.TimbreFiscalDigital.SelloCFD}
              </p>

              <b>SELLO DEL SAT</b>
              <p className="AjusteSello" style={{ fontSize: 10, color: '#000' }}>
                {cfdi.timbre.TimbreFiscalDigital.SelloSAT}
              </p>

              <br />
              <div className="d-flex">
                <div className="w-20">
                  <QRCode value={getQrSrc(cfdi.timbre)} size={170} />
                </div>
                <div style={{ maxWidth: 800, paddingLeft: 20 }}>
                  <TitleBlack>CADENA ORIGINAL DEL COMPLEMENTO DE CERTIFICACIÓN DIGITAL</TitleBlack>
                  <br />
                  <p className="AjusteSello" style={{ fontSize: 9, color: '#000' }}>
                    {getCadenaCFDI(cfdi.timbre)}
                  </p>

                  <div>
                    <div className="d-flex">
                      <RowHeader>No de Serie del Certificado del SAT:</RowHeader>
                      <div className="ml-2">{cfdi.timbre.NoCertificado}</div>
                    </div>

                    <div className="d-flex">
                      <RowHeader>Fecha y hora de certificación:</RowHeader>
                      <div className="ml-2">{cfdi.timbre.TimbreFiscalDigital.FechaTimbrado}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </WrapperPdf>
        ))}
      </div>
    </>
  )
}
