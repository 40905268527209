import React from 'react'
import { queryParams } from 'core/utils'
import { navigate } from 'gatsby'
import { BillingLayout } from 'modules/billing/pages/BillingPage/BillingLayout'

interface ImprimirFacturaParams {
  code: string
}

export default class ImprimirFactua extends React.Component<PageProps> {
  state = { code: '' }
  componentDidMount() {
    const params = queryParams<ImprimirFacturaParams>(this.props.location)
    if (!params.code || !this.validCode(params.code)) {
      return navigate('/')
    }
    this.setState({ code: params.code })
  }

  validCode(code: string) {
    let regex

    if (code.startsWith('G')) {
      regex = /G[0-9]{4}[A-Z0-9]{6}/
    } else if (code.startsWith('B')) {
      regex = /B[0-9]{4}[A-Z0-9]{6}/
    } else if (code.startsWith('ME')) {
      regex = /ME[0-9]{4}[A-Z0-9]{6}/
    } else if (code.startsWith('PP')) {
      regex = /PP[0-9]{4}[A-Z0-9]{6}/
    } else {
      regex = /R[0-9]{4}[A-Z0-9]{6}/
    }
    return regex.test(code)
  }

  render() {
    return <>{this.state.code && <BillingLayout code={this.state.code} />}</>
  }
}
